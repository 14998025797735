<template>
  <div>
    <!--begin::customer-->
    <div class="card-toolbar mb-5 d-flex justify-content-between">
      <div>
        <!--begin::Button-->
        <router-link v-if="$can('customers.create')" class="btn btn-primary font-weight-bolder"
                     to="/customers/customers/create">
          <v-icon>mdi-plus</v-icon>
          {{ $t('customers.add_customer') }}
        </router-link>
        <!--end::Button-->
        <button class="btn btn-outline-primary font-weight-bolder ml-5" @click="openAdvancedSearch">
          <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
        </button>
      </div>
      <div>
        <router-link v-if="$can('customers.export_import_customers')" class="btn btn-primary font-weight-bolder h-100"
                     to="/customers/export-import-customers">
          {{ $t('MENU.export_import_customers') }}
        </router-link>
      </div>
    </div>

    <div v-if="showAdvancedSearch" class="card card-custom mb-5">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row mb-5">
            <div class="form-group col-md-6">
              <label for="name">{{ $t('name') }}</label>
              <input id="name" v-model="filters.name" class="form-control" type="text">
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t('customers.countries') }}</label>
              <div class="input-group">
                <multiselect v-model="country"
                             :internal-search="false"
                             :multiple="false" :options="countries"
                             :placeholder="$t('customers.countries')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="code2"
                             @search-change="getCountries($event)">
                </multiselect>
              </div>
            </div>
            <!-- <div class="form-group col-md-6">
              <label for="category">{{ $t('category') }}</label>
              <input v-model="filters.category" type="text" id="category" class="form-control">
            </div> -->
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('customers.user_name') }}</label>
              <multiselect
                  v-model="user"
                  :multiple="false"
                  :options="users"
                  :placeholder="$t('customers.user_name')"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  :taggable="false"
                  label="name"
                  track-by="id"
                  @search-change="getUsers($event)">
              </multiselect>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('branch') }}</label>
              <multiselect v-model="branch"
                           :multiple="false"
                           :options="branches"
                           :placeholder="$t('branch')"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false"
                           :taggable="false"
                           label="name"
                           track-by="id">
              </multiselect>
            </div>

            <div class="form-group d-inline-flex col-md-6">
              <button id="m_search" class="btn btn-primary mt-auto mr-2" type="button" @click="doFilter">
                <span><i class="fas fa-search"></i>
                  <span>{{ $t('search') }}</span>
                </span>
              </button>
              <button id="m_reset" class="btn btn-danger mt-auto mr-2" type="button" @click="resetFilter">
                <span><i class="fas fa-trash-restore"></i>
                  <span>{{ $t('reset_search') }}</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom">
      <!-- <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t('customers.customers_management')}}
            <span class="text-muted pt-2 font-size-sm d-block"
              >{{ $t(('customers.customers') )}}</span
            >
          </h3>
        </div>

      </div> -->
      <div class="card-body">

        <!-- Filter -->
        <div>
          <div class="row justify-content-end p-4">
            <custom-export-data v-if="$can('customers.export_data')" :data-list="dataList" :fields="json_fields"
                                :file-name="$t('customers.customers_management')"></custom-export-data>
          </div>
        </div>
        <!-- End Filter -->

        <v-server-table ref="table" :class="'dataTable table-row-dashed'" :columns="columns" :options="options">
          <template slot="#" slot-scope="props">
            <span>{{props.index}}</span>
          </template>
          <template slot="fullname" slot-scope="props">
            <span style="text-wrap: wrap;word-break: break-word;">{{ props.row.fullname }}</span>
          </template>
          <template slot="mobile" slot-scope="props">
            <span class="tel_number">{{ props.row.mobile }}</span>
          </template>
          <template slot="classification" slot-scope="props">
            <span><i v-if="props.row.classification && props.row.classification.color" :style="'color:'+props.row.classification.color"
                     class="fa fa-circle"></i></span>
          </template>
          <template slot="status" slot-scope="props">
            <b-form-checkbox v-if="$can('customers.change_status')"
                             :key="props.row.id" v-model="props.row.is_active"
                             :name="'check-button'+props.row.id"
                             size="lg"
                             switch @change="changeStatus(props.row.id, props.row.is_active)">
            </b-form-checkbox>

            <b-form-checkbox v-else :key="props.row.id" v-model="props.row.is_active" :disabled="true"
                             :name="'check-button'+props.row.id" size="lg" switch></b-form-checkbox>

          </template>

          <template slot="actions" slot-scope="props">
            <v-btn v-if="$can('customers.update')" v-b-tooltip.hover :title="$t('edit')" :to="`/customers/customers/edit/${props.row.id}`" color="pink"
                   icon>
              <v-icon class="mr-2 text-info " small>mdi-pencil</v-icon>
            </v-btn>
            <v-icon v-if="$can('customers.delete')" v-b-tooltip.hover :title="$t('delete')" class="text-danger" small
                    @click="deleteItem(props.row)">mdi-delete
            </v-icon>
            <v-btn v-if="$can('customers.list')" :title="$t('view')" :to="`/customers/customers/view/${props.row.id}`" color="pink"
                   icon>
              <v-icon class="mr-2 text-info" small>mdi-eye</v-icon>
            </v-btn>

            <b-dropdown id="dropdown-offset" :text="$t('more_actions')" class="m-2" variant="outline-primary">
              <a v-if="$can('customers.open_balance')" :key="props.row.id" class="dropdown-item"
                 href="javascript:;" @click="addOpenBalance(props.row.id)">
                {{ $t('customers.open_balance') }}
              </a>
              <router-link v-if="$can('customers.send_mail_to_customer')" :to="`/customer/send/mail/${props.row.id}`"
                           class="dropdown-item">
                {{ $t('send_mail_to_customer') }}
              </router-link>
              <button v-if="$can('customers.account_statement')" :key="'supplier-account-statement'+props.row.id"
                      class="dropdown-item" @click="showAccountStatement(props.row)">
                {{ $t('account_statement') }}
              </button>
              <button v-if="isSystemUser" :key="'AdjustmentCustomerStatement'+props.row.id"
                      class="dropdown-item"
                      @click="showAdjustmentCustomerStatementModal(props.row.id)">
                {{ $t('adjustment_customer_statement') }}
              </button>
              <!--back_off_the_opening_balance-->
              <button v-if="isSystemUser" class="dropdown-item" @click="retractFromTheOpeningBalance(props.row.id)">
                {{ $t('back_off_the_opening_balance') }}
              </button>

              <button v-if="isSystemUser" :key="'adjustmentCustomerBalanceItem'+props.row.id" class="dropdown-item"
                      @click="adjustmentCustomerBalanceItem(props.row.id)">
                {{ $t('customer_balance_adjustment') }}
              </button>
            </b-dropdown>
          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->


    <b-modal ref="open-balance-modal" :title="$t('customers.open_balance')" hide-footer size="xl">
      <customer-open-balance-form :current-id="innerId"
                                  @hide-modal="hideModal()"
                                  @handling-data="getDataAfterSave">
      </customer-open-balance-form>
    </b-modal>


    <b-modal ref="account-statement-modal" :title="$t('account_statement')" hide-footer size="xl">
      <div>
        <div class="form-group row">
          <div class="col-lg-4 mb-5">
            <label>{{ $t('from_date') }}</label>
            <input v-model="account_statement.from_date" class="form-control" type="date"/>
          </div>
          <div class="col-lg-4 mb-5">
            <label>{{ $t('to_date') }}</label>
            <input v-model="account_statement.to_date" class="form-control" type="date"/>
          </div>
          <!--          <div class="col-lg-4 mb-5">-->
          <!--            <div class="form-group">-->
          <!--              <label>{{ $t('customers.is_related_customers') }}:</label>-->
          <!--              <b-form-checkbox size="lg" v-model="account_statement.is_related_customers" name="check-button" switch></b-form-checkbox>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div class="pl-0 pr-0 pb-0">
          <div class="row">
            <div class="col-lg-6">
              <button class="btn btn-primary mr-2" type="reset" @click="showReportAccountStatement">{{
                  $t('show')
                }}
              </button>

              <!--              <button type="reset" class="btn btn-primary mr-2" @click="getAccountStatement('print')">{{ $t('search_and_print') }}</button>-->
              <!--              <button type="reset" class="btn btn-primary mr-2" @click="getAccountStatement('pdf')">{{ $t('search_and_export_pdf') }}</button>-->
              <!--              <button type="reset" class="btn btn-secondary" @click="hideAccountStatementModal">{{ $t('cancel') }}</button>-->
            </div>
          </div>
        </div>
      </div>

    </b-modal>

    <b-modal ref="adjustment-customer-statement-modal" :title="$t('adjustment_customer_statement')" hide-footer
             size="sm" @hide="hideAdjustmentCustomerStatementModal">
      <div>
        <div class="form-group row">
          <div class="col-lg-12 mb-5">
            <label>{{ $t('from_date') }}</label>
            <input v-model="start_date" class="form-control" type="date"/>
          </div>
        </div>
        <div class="pl-0 pr-0 pb-0">
          <div class="row">
            <div class="col-lg-6">
              <button class="btn btn-primary mr-2" type="reset" @click="adjustmentCustomerStatement">{{
                  $t('adjustment')
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import CustomerOpenBalanceForm from "@/view/content/forms/CustomerOpenBalanceForm.vue";
import {mapGetters} from "vuex";

export default {
  name: "index-customers",
  components: {CustomerOpenBalanceForm},
  data() {
    return {
      mainRoute: 'customers/customers',
      routeChangeStatus: 'customers/customer/change-status',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,
      dataList: [],
      filter: {
        sortBy: 'id',
      },
      filters: {
        name: this.$route.query.customer_name ? this.$route.query.customer_name : '',
        category: '',
        country: '',
        user_id: '',
        branch_id: '',
      },
      columns: ['#','id', 'fullname', 'customer_code', 'mobile', 'country_name', 'city_name', 'classification', 'user_name', 'created_at', 'status', 'actions'],
      data: [],
      country: null,
      countries: [],
      category: null,
      categories: [],

      innerId: null,
      customerOpenBalanceId: null,
      account_statement: {
        from_date: null,
        to_date: null,
        is_related_customers: false,
      },
      user: null,
      users: [],
      branch: null,
      branches: [],
      customer_name: null,
      start_date: null,
    }
  },
  watch: {
    user: function (val) {
      if (val) {
        this.filters.user_id = val.id;
      } else {
        this.filters.user_id = null;
      }
    },
    branch: function (val) {
      if (val) {
        this.filters.branch_id = val.id;
      } else {
        this.filters.branch_id = null;
      }
    },
    country: function (val) {
      if (val) {
        this.filters.country = val.code2;
      } else {
        this.filters.country = '';
      }
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    isSystemUser: function () {
      return this.currentUserPersonalInfo.is_system_user
    },

    json_fields: function () {
      let fields = {};
      fields[this.$t('customers.full_name')] = 'fullname';
      fields[this.$t('customers.code')] = 'customer_code';
      fields[this.$t('customers.country')] = 'country_name';
      fields[this.$t('customers.city')] = 'city_name';
      fields[this.$t('customers.mobile')] = 'mobile';
      fields[this.$t('customers.phone')] = 'phone';
      fields[this.$t('customers.code')] = 'customer_code';
      fields[this.$t('customers.address_1')] = 'address_1';
      fields[this.$t('customers.address_2')] = 'address_2';
      fields[this.$t('customers.postcode')] = 'postcode';
      fields[this.$t('customers.state')] = 'state';
      fields[this.$t('customers.notes')] = 'notes';

      return fields;
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          id: that.$t('id'),
          fullname: that.$t('customers.full_name'),
          customer_code: that.$t('customers.code'),
          mobile: that.$t('customers.mobile'),
          country_name: that.$t('customers.country'),
          city_name: that.$t('customers.city'),
          classification: that.$t('customers.classification'),
          user_name: that.$t('customers.user_name'),
          created_at: that.$t('created_at'),
          status: that.$t('status'),
          actions: that.$t('global.actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  // created() {
  //     ApiService.get('customers').then(response => {
  //         this.data = response.data.data.data;
  //     }).catch(e => {
  //         console.log(e);
  //     });
  // },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.customers_management")}]);
    if (this.filters.name) {
      this.showAdvancedSearch = true;
    }
    this.getBranches();
  },
  methods: {
    changeStatus(id, status) {
      ApiService.patch(this.routeChangeStatus + '/' + id, {
        is_active: (status ? 1 : 0),
      }).then((response) => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.$errorAlert(error);
      });
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.name = '';
      this.filters.category = '';
      this.filters.country = '';
      this.filters.user_id = '';
      this.filters.branch_id = '';
      this.category = null;
      this.country = null;
      this.user = null;
      this.branch = null;
      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },

    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },

    getCountries(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get("base/dependency/countries", {params: {filter: filter}}).then((response) => {
          this.countries = response.data.data;
        });
      } else {
        this.countries = [];
      }
    },

    actionDelete(item) {
      ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error)
      })
    },

    adjustmentCustomerStatement() {
      if (this.innerId)
        ApiService.post(`${this.mainRoute}/${this.innerId}/adjust-balance`, {
          start_date: this.start_date
        }).then((response) => {
          this.$successAlert(response.data.message)
        }).catch((error) => {
          this.$errorAlert(error)
        })
    },
    adjustmentCustomerBalanceItem(id) {
      this.$confirmAlert(this.$t('are_you_sure_that_you_want_to_adjust_customer_balance'), this.adjustmentCustomerBalance, id);
    },
    adjustmentCustomerBalance(id) {
      if (id)
        ApiService.get(`${this.mainRoute}/${id}/customer_balance_adjustment`).then((response) => {
          this.$successAlert(response.data.message)
        }).catch((error) => {
          this.$errorAlert(error)
        })
    },
    retractFromTheOpeningBalance(id) {
      this.$confirmAlert(this.$t('are_you_sure_that_you_want_to_retract_from_the_opening_balance'), this.retractFromTheOpeningBalanceItem, id);
    },
    retractFromTheOpeningBalanceItem(id) {
      if (id)
        ApiService.get(`${this.mainRoute}/${id}/open_balance_rollback`).then((response) => {
          this.$successAlert(response.data.message)
        }).catch((error) => {
          this.$errorAlert(error)
        })
    },


    showModal() {
      this.$refs['open-balance-modal'].show()
    },
    hideModal() {
      this.$refs['open-balance-modal'].hide();
    },
    addOpenBalance(id) {
      this.innerId = id;
      this.showModal();
    },
    getDataAfterSave() {
      this.innerId = null;
      this.doFilter();
    },

    showAccountStatementModal() {
      this.$refs['account-statement-modal'].show()
    },
    hideAccountStatementModal() {
      this.$refs['account-statement-modal'].hide();

      this.account_statement.from_date = null;
      this.account_statement.to_date = null;
      this.account_statement.is_related_customers = false;
    },
    showAdjustmentCustomerStatementModal(id) {
      this.innerId = id;
      this.$refs['adjustment-customer-statement-modal'].show()
    },
    hideAdjustmentCustomerStatementModal() {
      this.$refs['adjustment-customer-statement-modal'].hide();
      this.innerId = null;
      this.start_date = null;
    },
    showAccountStatement(row) {
      this.innerId = row.id;
      this.customer_name = row.fullname;
      this.showAccountStatementModal();
    },
    getAccountStatement(type) {
      let _query = this.account_statement;
      _query.is_related_customers = _query.is_related_customers ? 1 : 0;
      let route = this.$router.resolve({
        path: '/print/customer-account-statement/' + this.innerId + '/' + type,
        query: _query
      });
      window.open(route.href, '_blank');
    },
    showReportAccountStatement() {
      let _query = {
        from_date: this.account_statement.from_date,
        to_date: this.account_statement.to_date,
        customer_id: this.innerId,
        customer_name: this.customer_name,
      };
      let route = this.$router.resolve({path: '/reports/simple-clients-balance-report', query: _query});
      window.open(route.href, '_blank');
    },
    getUsers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/users`, {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
      }
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
  },
};
</script>
